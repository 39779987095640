import { addNotify } from 'features/Notify';
import { setActuallyClientId, getClients as getAuthClients} from 'features/Auth/logic/actions';

const actionTypes = {
 GET_DATA: 'GET_DATA',
 GET_CONVERSION: 'GET_CONVERSION',
 SET_IS_PROCESS: 'admin/SET_IS_PROCESS',
 GET_GAMES: 'admin/GET_GAMES',
 CLEAR_DATA: 'admin/CLEAR_DATA',
}

const getByUrl = ({ url, filters }) => async (dispatch, getState, extra) => {
  dispatch ({type: actionTypes.SET_IS_PROCESS, payload: true});
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, onlyClientID } = getState().auth;
  const client_id = role === 1 ? +filters?.client_id || onlyClientID : userId;

  const response = await api.admin.getByUrl({
    url,
    filters: filters && { ...filters, client_id, }
  });

  if (response.success) {
    dispatch({ type: actionTypes.GET_DATA, payload: response.data })
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });

}

const deleteByUrl = ({getUrl, data, refechData}) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const url = getUrl(getState());
  const response = await api.admin.deleteByUrl({
    url,
    id: data.id
  });
  
  if (response.success) {
    dispatch(refechData(getState()));
    dispatch(addNotify(locale.successRemoved, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const getTransfers = filters => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, onlyClientID } = getState().auth;
  const client_id = role === 1 ? +filters.client_id || onlyClientID : userId;
  const response = await api.admin.getTransfers({ ...filters, client_id, type: filters.type ? +filters.type : undefined });
  
  if (response.success) {
    dispatch({ type: actionTypes.GET_DATA, payload: response.data })
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const getCurrencies = (clientId) => async (dispatch, getState, extra) => {
  const { locale } = getState().locale;
  if (clientId !== undefined) {
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
    const { api } = extra;
    const response = await api.admin.getCurrencies(clientId);
    
    if (response.success) {
      dispatch({ type: actionTypes.GET_DATA, payload: response.data })
      dispatch(setActuallyClientId(clientId));
    } else {
      dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
    }
  
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
  } else {
    dispatch(addNotify(locale.chooseClient, 'error'))
  }
}

const getClientGames = (clientId) => async (dispatch, getState, extra) => {
  const { locale } = getState().locale;
  const { role, userId, onlyClientID } = getState().auth;
  const client_id = role === 1 ? clientId || onlyClientID : userId;

  if (client_id !== undefined && client_id !== null) {
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
    const { api } = extra;
    const response = await api.admin.getClientGames(client_id);
    
    if (response.success) {
      dispatch({ type: actionTypes.GET_DATA, payload: response.data })
      dispatch(setActuallyClientId(client_id));
    } else {
      dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
    }
  
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
  } else {
    dispatch(addNotify(locale.chooseClient, 'error'))
    dispatch(clearData())
  }
}

const setActiveGame = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.setActiveGame(data.name);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/properties' }))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const switchAllGames = is_active => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.switchAllGames(is_active);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/properties' }))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const editGame = (data, editableElement, callback) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.editGame(data, editableElement.name);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/properties' }))
    callback && callback();
    dispatch(addNotify(locale.successEdit, 'success'))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const createClient = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.createClient({
    ...data.fields,
    royalty_coefficient: +data.fields.royalty_coefficient,
  });
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/clients' }));
    dispatch(getAuthClients());
    dispatch(addNotify(locale.successCreated, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const editClient = (data, editableElement, callback) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.editClient({
    ...data,
    royalty_coefficient: +data.royalty_coefficient
  }, editableElement.id);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/clients' }))
    callback && callback();
    dispatch(addNotify(locale.successEdit, 'success'))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const setToken = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.setToken(data.data);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/clients' }));
    dispatch(addNotify(locale.successSetToken, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const createCurrency = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { actuallyClientId } = getState().auth;
  const response = await api.admin.createCurrency(data.fields, actuallyClientId);
  
  if (response.success) {
    dispatch(getCurrencies(actuallyClientId));
    dispatch(addNotify(locale.successCreated, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const createCurrencyRate = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.createCurrencyRate(data.fields);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/set-currency-rate' }));
    dispatch(addNotify(locale.successCreated, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const editCurrencyRate = (data, editableElement, callback) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.editCurrencyRate({ rate: +data.rate }, editableElement.id);
  
  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/set-currency-rate' }))
    callback && callback();
    dispatch(addNotify(locale.successEdit, 'success'))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const genHashes = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.genHashes(data.data);
  
  if (response.success) {
    dispatch(getByUrl({ url:  'api/admin/generate_hashes' }));
    dispatch(addNotify(locale.successSetToken, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const editClientGame = (data, editableElement, callback) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, actuallyClientId } = getState().auth;
  const client_id = role === 1 ? actuallyClientId : userId;
  const response = await api.admin.editClientGame(data, editableElement.name, client_id);
  
  if (response.success) {
    dispatch(getClientGames(client_id))
    callback && callback();
    dispatch(addNotify(locale.successEdit, 'success'))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const setActiveClientGame = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, actuallyClientId } = getState().auth;
  const client_id = role === 1 ? actuallyClientId : userId;
  const response = await api.admin.setActiveClientGame(data.name, client_id);
  
  if (response.success) {
    dispatch(getClientGames(client_id))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const switchAllClientGames = is_active => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, actuallyClientId } = getState().auth;
  const client_id = role === 1 ? actuallyClientId : userId;
  const response = await api.admin.switchAllClientGames(is_active, client_id);
  
  if (response.success) {
    dispatch(getClientGames(client_id))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const clearData = () => async dispatch => dispatch({ type: actionTypes.CLEAR_DATA });

export {
  actionTypes,
  deleteByUrl,
  getByUrl,
  setActiveGame,
  editGame,
  createClient,
  setToken,
  getTransfers,
  getCurrencies,
  createCurrency,
  switchAllGames,
  createCurrencyRate,
  editCurrencyRate,
  genHashes,
  editClient,
  getClientGames,
  editClientGame,
  setActiveClientGame,
  clearData,
  switchAllClientGames,
}
