import {
  betsTitles,
  gamesTitles,
  firecrashTitles,
  clientsTitles,
  transfersTitles,
  currenciesTitles,
  spinTitles, 
  currencyRatesTitles,
  hashesTitles,
  clientGamesTitles,
  glassBridgeTitles,
  plinkoTitles,
  kenoTitles,
} from './titles';
import {
  hiloBetsFilters,
  firecrashBetsFilters,
  conversionFilters,
  transfersFilters,
  spinFilters,
  currenciesFilters,
  clientGamesFilters,
  glassBridgeFilters
} from './filters';
import { hiloBetsButtons, firecrashBetsButtons, transfersButtons, spinButtons, glassBridgeButtons } from './buttons';
import { conversionFialds } from './graphics';
import { conversionAdditionals } from './specificAdditionals';
import { gamesChangedKeys, clientGamesChangedKeys } from './changedKeys';
import { gamesGlobalButtons, clientGamesGlobalButtons } from './globalButtons';
import { actions } from '../';
import { getRequestFilters } from './';

const pages = [
  {
    dataName: 'records',
    textIdent: 'transfers',
    link: '/transfers',
    titles: transfersTitles,
    filters: transfersFilters,
    buttons: transfersButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getTransfers(getRequestFilters(filters, page, sortField)),
  },
  {
    dataName: 'records',
    textIdent: 'spin2win',
    link: '/bets-spin',
    titles: spinTitles,
    filters: spinFilters,
    buttons: spinButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({ url: '/api/admin/spin_bets', filters: getRequestFilters(filters, page, sortField),
    }),
  },
  {
    dataName: 'records',
    textIdent: 'penalty',
    link: '/bets-penalty',
    titles: spinTitles,
    filters: spinFilters,
    buttons: spinButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/penalty_bets', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'hilo',
    link: '/bets-hilo',
    titles: betsTitles,
    filters: hiloBetsFilters,
    buttons: hiloBetsButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/hilo_bets', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'firecrash',
    link: '/bets-firecrash',
    titles: firecrashTitles,
    filters: firecrashBetsFilters,
    buttons: firecrashBetsButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/firework_bets', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'zeppelin',
    link: '/zeppelin_bets ',
    titles: firecrashTitles,
    filters: firecrashBetsFilters,
    buttons: firecrashBetsButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/zeppelin_bets ', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'glassBridge',
    link: '/glass_bridge',
    titles: glassBridgeTitles,
    filters: glassBridgeFilters,
    buttons: glassBridgeButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({ url: '/api/admin/bridge_games', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'chicken',
    link: '/cookie_games',
    titles: glassBridgeTitles,
    filters: glassBridgeFilters,
    buttons: glassBridgeButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/cookie_games', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'keno',
    link: '/keno_games',
    titles: kenoTitles,
    filters: glassBridgeFilters,
    buttons: glassBridgeButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/keno_games', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'CoinFlip',
    link: '/coinflip_games',
    titles: glassBridgeTitles,
    filters: glassBridgeFilters,
    buttons: glassBridgeButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/coinflip_games', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'dino',
    link: '/bets-dino',
    titles: firecrashTitles,
    filters: firecrashBetsFilters,
    buttons: firecrashBetsButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/dino_bets', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'records',
    textIdent: 'plinko',
    link: '/plinko_games',
    titles: plinkoTitles,
    filters: glassBridgeFilters,
    buttons: glassBridgeButtons,
    isBig: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({url: '/api/admin/plinko_games', filters: getRequestFilters(filters, page, sortField)}),
  },
  {
    dataName: 'bets',
    textIdent: 'conversion',
    accessRoles: [1],
    link: '/conversion',
    filters: conversionFilters,
    specificAdditionals: conversionAdditionals,
    graphics: conversionFialds,
    getInfo:  ({ filters, page }) => actions.getByUrl({url: '/api/admin/conversion', filters: getRequestFilters(filters, page)}),
  },
  {
    dataName: 'games_properties',
    textIdent: 'games',
    link: '/games',
    accessRoles: [1],
    titles: gamesTitles,
    withoutAdditional: true,
    withoutFilters: true,
    isBig: true,
    isEditable: true,
    editTextIdent: 'name',
    changedKeys: gamesChangedKeys,
    globalButtons: gamesGlobalButtons,
    edit: ({ data, editableElement, callback }) => actions.editGame(data, editableElement, callback),
    getInfo:  () => actions.getByUrl({ url: '/api/admin/properties' }),
  },
  {
    dataName: 'clients',
    textIdent: 'clients',
    link: '/clients',
    accessRoles: [1],
    titles: clientsTitles,
    withoutAdditional: true,
    withoutFilters: true,
    isBig: true,
    isCreatable: true,
    isEditable: true,
    create: data => actions.createClient(data),
    getInfo:  () => actions.getByUrl({ url: '/api/admin/clients' }),
    edit: ({ data, editableElement, callback }) => actions.editClient(data, editableElement, callback),
  },
  {
    dataName: 'records',
    textIdent: 'currencies',
    link: '/currencies',
    accessRoles: [1],
    titles: currenciesTitles,
    withoutAdditional: true,
    filters: currenciesFilters,
    isBig: false,
    isCreatable: true,
    isRemovable: true,
    create: data => actions.createCurrency(data),
    remove: data => actions.deleteByUrl({
      getUrl: state => `/api/admin/client-currencies/${state.auth.actuallyClientId}`,
      data,
      refechData: state => actions.getCurrencies(state.auth.actuallyClientId),
    }),
    getInfo: ({ filters }) => actions.getCurrencies(getRequestFilters(filters).client_id),
  },
  {
    dataName: 'records',
    textIdent: 'currencyRates',
    link: '/currency-rates',
    accessRoles: [1],
    titles: currencyRatesTitles,
    withoutAdditional: true,
    withoutFilters: true,
    isBig: false,
    isCreatable: true,
    isRemovable: true,
    isEditable: true,
    editTextIdent: 'from_currency',
    create: data => actions.createCurrencyRate(data),
    remove: data => actions.deleteByUrl({
      getUrl: () => '/api/admin/set-currency-rate',
      data,
      refechData: () => actions.getByUrl({url: '/api/admin/set-currency-rate'}),
    }),
    edit: ({ data, editableElement, callback }) => actions.editCurrencyRate(data, editableElement, callback),
    getInfo:  () => actions.getByUrl({url: '/api/admin/set-currency-rate'}),
  },
  // {
  //   dataName: 'table_names',
  //   textIdent: 'hashes',
  //   link: '/hashes',
  //   accessRoles: [1],
  //   titles: hashesTitles,
  //   withoutAdditional: true,
  //   withoutFilters: true,
  //   isBig: false,
  //   getInfo: () => actions.getByUrl({ url: 'api/admin/generate_hashes' }),
  // },
  {
    dataName: 'games_properties',
    textIdent: 'gamesSettings',
    link: '/games-settings',
    filters: clientGamesFilters,
    titles: clientGamesTitles,
    withoutAdditional: true,
    isBig: true,
    isEditable: true,
    editTextIdent: 'name',
    changedKeys: clientGamesChangedKeys,
    globalButtons: clientGamesGlobalButtons,
    edit: ({ data, editableElement, callback }) => actions.editClientGame(data, editableElement, callback),
    getInfo:  ({ filters }) => actions.getClientGames(getRequestFilters(filters).client_id),
  },
]

export {
  pages
}