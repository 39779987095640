import dayjs from 'dayjs';

export const inputTypes = {
  number: 'number',
  text: 'text',
  date: 'date',
  select: 'select',
  bool: 'bool',
  json: 'json',
  array: 'array',
}

export const getDataFromValue = (value, type) => {
  switch (type) {
    case inputTypes.array: return value.toUpperCase().replaceAll(' ', '').split(',');
    default: return value;
  }
};

export const getValueFromData = (data, type) => {
  switch (type) {
    case inputTypes.number: return data;
    case inputTypes.text: return data;
    case inputTypes.date: return dayjs(data).format('DD.MM HH:mm:ss');
    case inputTypes.json: return JSON.stringify(data, null, 2);
    case inputTypes.bool: return data ? '+' : '-';
    case inputTypes.array: return data;
    default: return data;
  }
}